
.icon_color{
	color: $iconColor;
}
.fbIcon{
	color:$fbColor;
}
.slider_holder{width:1000px;}
.slider_bg{background-image: url(../img/index_bg.jpg);}
.carousel-caption{
	/*text-shadow: 1px 1px #F3E7CF, -1px -1px #56433D;*/
	span{color: #0054ff;}
	p{font-size: 2em; color: #0054ff;}
}
.textCol2{column-count:2; column-gap: 60px; column-rule: 1px solid#ccc;}
.footerBg{border-top:5px solid #90a0c2; background:#515a6d;}
.mth{margin-top:70px;}
.pagePath{ list-style: none; float: left;
	li{ display:inline-block; margin-right:4px;}
}
/***********BACK TO TOP**********************/
#back-top {position: fixed; bottom: 30px; right: 0; margin-right:6%; z-index:1200; cursor:pointer;}
#back-top a {width: 48px; display: block; text-align: center; font-size:0.45em; text-transform: uppercase; text-decoration: none; color: #bbb;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
}
#back-top a:hover {color: #000;}
/* изображение со стрелкой (тег span) */
#back-top span {width: 48px; height: 48px; display: block; margin-bottom: 7px; background: #ddd url(../img/up-arrow.png) no-repeat center center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
	-moz-box-shadow: 0px 0px 3px #888;
	-webkit-box-shadow: 0px 0px 3px #888;
	box-shadow: 0px 0px 3px #888;
	-moz-box-shadow: 0px 0px 3px #888;
	-webkit-box-shadow: 0px 0px 3px #888;
	box-shadow: 0px 0px 3px #888;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
}
#back-top a:hover span {background-color: #777;}
/***********END BACK TO TOP**********************/